<template>
  <section class="transfer cheque">
    <h2 class="heading">Cheque Deposit</h2>

    <form
      class="form"
      @submit.prevent="submitForm"
      novalidate="true"
      autocomplete="off"
    >
      <section class="deposit__to">
        <div class="heading-four">
          <h2>Deposit to</h2>
          <p>Destination account</p>
        </div>
        <div>
          <div class="sources-two">
            <div class="sources-two__wrapper">
              <div
                v-for="account in userCardAccounts"
                :key="account.NUBAN"
                @click="$emit('sourceDetails', account)"
                class="sources-two__card"
              >
                <label :for="account.NUBAN" class="sources-two__left">
                  <p>{{ account.NUBAN }} | {{ account.ACCT_TYPE }}</p>
                  <p>
                    {{ account.WorkingBalance | formatCurrencyTwo }}
                    {{ account.Currency_Code }}
                  </p>
                  <p>{{ account.AccountDescp | capitalize }}</p>
                </label>
                <input
                  type="radio"
                  :value="account"
                  :id="account.NUBAN"
                  name="deposit-account"
                  class="sources-two__right"
                  v-model.lazy="$v.form.destinationAccount.$model"
                />
              </div>
            </div>
          </div>
          <small
            class="error"
            v-if="submitted && !$v.form.destinationAccount.required"
            >Destination account is required</small
          >
        </div>
      </section>

      <section class="deposit__details">
        <div class="heading-four">
          <h2>Deposit details</h2>
          <p>All fields are required</p>
        </div>
        <div>
          <div class="form__group--four">
            <label for="depositorName">
              <input
                type="text"
                id="depositorName"
                name="depositorName"
                placeholder="Depositor's name"
                class="form__input"
                v-model.lazy="$v.form.depositorName.$model"
                :class="{
                  'is-invalid': submitted && $v.form.depositorName.$error
                }"
            /></label>
            <small
              class="error"
              v-if="submitted && !$v.form.depositorName.required"
              >Depositor's name is required</small
            >
          </div>
          <div class="form__group--four">
            <label for="amount" class="form__label">
              <input
                type="tel"
                id="amount"
                name="amount"
                placeholder="Amount on cheque"
                class="form__input"
                v-model.lazy="$v.form.amount.$model"
                :class="{
                  'is-invalid': submitted && $v.form.amount.$error
                }"
            /></label>
            <small class="error" v-if="submitted && !$v.form.amount.required"
              >Amount is required</small
            >
            <br />
            <small class="error" v-if="submitted && !$v.form.amount.numeric"
              >Needs to be numeric only.</small
            >
          </div>
          <div class="form__group--four">
            <label for="chequeNumber">
              <input
                type="tel"
                id="chequeNumber"
                name="chequeNumber"
                placeholder="Cheque Number"
                class="form__input"
                v-model.lazy="$v.form.chequeNumber.$model"
                :class="{
                  'is-invalid': submitted && $v.form.chequeNumber.$error
                }"
            /></label>
            <small
              class="error"
              v-if="submitted && !$v.form.chequeNumber.required"
              >Cheque number is required</small
            >
            <br />
            <small
              class="error"
              v-if="submitted && !$v.form.chequeNumber.numeric"
              >Needs to be numeric only.</small
            >
          </div>
          <div class="form__group--four">
            <label for="date" class="form__label">
              <input
                type="text"
                onfocus="(this.type='date')"
                id="date"
                name="date"
                placeholder="Date on cheque"
                class="form__input"
                v-model.lazy="$v.form.chequeDate.$model"
                :class="{
                  'is-invalid': submitted && $v.form.chequeDate.$error
                }"
            /></label>
            <small
              class="error"
              v-if="submitted && !$v.form.chequeDate.required"
              >Cheque date is required</small
            >
          </div>
        </div>
      </section>

      <section class="deposit__images">
        <div class="heading-four">
          <h2>Cheque photos</h2>
          <p>Front and back photos of the cheque</p>
        </div>
        <div class="upload-container">
          <div class="upload-wrapper">
            <div class="upload-form">
              <label class="upload-label" for="imgFrontUploader"
                >Select front photo</label
              >
              <input
                id="imgFrontUploader"
                class="custom-file-input"
                type="file"
                accept="image/*"
                @change="loadFrontImage($event)"
              />
            </div>
            <img class="upload-image" id="front-output" />
            <small
              class="error"
              v-if="submitted && !$v.form.chequeFront.required"
              >Cheque front photo is required</small
            >
          </div>
          <div class="upload-wrapper">
            <div class="upload-form">
              <label class="upload-label" for="imgBackUploader"
                >Select back photo</label
              >
              <input
                id="imgBackUploader"
                class="custom-file-input"
                type="file"
                accept="image/*"
                @change="loadBackImage($event)"
              />
            </div>
            <img class="upload-image" id="back-output" />
            <small
              class="error"
              v-if="submitted && !$v.form.chequeBack.required"
              >Cheque back photo is required</small
            >
          </div>
        </div>
      </section>
      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Continue</span>
      </button>
    </form>
  </section>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";

export default {
  computed: mapState(["userCardAccounts", "user", "loading"]),
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      submitted: false,
      form: {
        destinationAccount: "",
        depositorName: "",
        amount: "",
        chequeNumber: "",
        chequeDate: "",
        chequeFront: "",
        chequeBack: ""
      }
    };
  },
  validations: {
    form: {
      destinationAccount: { required },
      depositorName: { required },
      amount: { required, numeric },
      chequeNumber: { required, numeric },
      chequeDate: { required },
      chequeFront: { required },
      chequeBack: { required }
    }
  },
  methods: {
    loadFrontImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      let reader = new FileReader();

      reader.onload = () => {
        let output = document.getElementById("front-output");
        output.src = reader.result;
        this.form.chequeFront = output.src;
      };

      reader.readAsDataURL(files[0]);
    },
    loadBackImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      let reader = new FileReader();

      reader.onload = () => {
        let output = document.getElementById("back-output");
        output.src = reader.result;
        this.form.chequeBack = output.src;
      };

      reader.readAsDataURL(files[0]);
    },
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      // console.log(this.form);

      let payload = {
        accountNumber: this.form.destinationAccount.NUBAN,
        payee: this.form.destinationAccount.AccountDescp,
        imei: "web",
        branchSortCode: "",
        withdrawerName: this.form.depositorName,
        accountEmail: this.user.profileInfo.email,
        payeeBvn: this.user.profileInfo.BVN,
        beneficiary: this.form.depositorName,
        amount: this.form.amount,
        depositor: this.form.depositorName,
        frontImage: this.form.chequeFront,
        rearImage: this.form.chequeBack,
        chequeDate: this.form.chequeDate

        // beneficiaryBvn: "22120000000",
      };

      this.$store.commit("toggleLoading", true);

      api
        .handleChequeDeposit(payload)
        .then(response => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true
              }
            );
          }
        })
        .catch(error => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000
          });
        });
    }
  },
  mounted() {
    // console.log(this.user.profileInfo);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.upload-label {
  font-family: "Red Hat Display";
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover.label {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active.label {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.upload-container {
  border: 1px solid #f2f2f2;
  border-radius: 0.3rem;
  margin: 0 auto 3rem;
  padding: 2rem;
}
.upload-wrapper:not(:last-of-type) {
  margin-bottom: 3rem;
}
.upload-form {
  margin-bottom: 1rem;
}
.upload-image {
  display: block;
  width: 100%;
  object-fit: cover;
}
</style>
